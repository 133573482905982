import axios from "axios";
import { useState } from "react";

export default function AddNewUser() {
  const [formData, setFormData] = useState({});

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);
    axios
      .post(
        "https://www.medspanetwork.com/api/v2/Merz/accounts/create-merz-rep",
        formData
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <h1>Add New User</h1>
      <form
        style={{ display: "flex", flexDirection: "column", width: 300 }}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <input
          type="text"
          placeholder="First Name"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              firstName: e.target.value,
            }));
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              lastName: e.target.value,
            }));
          }}
        />
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              TBMEmail: e.target.value,
            }));
          }}
        />
        <input
          type="number"
          placeholder="Phone Number"
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              TBMPhoneNumber: e.target.value,
            }));
          }}
        />
        <input type="submit" />
      </form>
    </div>
  );
}
