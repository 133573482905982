import React from "react";
import moment from "moment";

function CompanyOrderRow({ order }) {
  const { 0: orderId, 1: orderAmount, 2: orderDate } = order;
  const formattedDate = moment(orderDate).format("MM/DD/YY");

  return (
    <div className="order-history-row">
      <div>{formattedDate}</div>
      <div>
        {Number(orderAmount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </div>

      <a
        href={"/viewTransaction/" + orderId}
        className="view-company-purchase-button"
      >
        View Details
      </a>
    </div>
  );
}

export default CompanyOrderRow;
