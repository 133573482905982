import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { UseLocaleStorage } from "../Hooks/useLocalStorage";
import { useEffect } from "react";
import axios from "axios";

const UserInformationContext = createContext({});

export function useUser() {
  return useContext(UserInformationContext);
}

export function UserInformationProvider({ children }) {
  const [userInformation, setUserInformation] = UseLocaleStorage(
    "UserInformation",
    []
  );

  useEffect(() => {
    axios
      .post("/api/v2/Merz/accounts/getUserDashboardData", {
        id: JSON.parse(localStorage.getItem("userId")),
      })
      .then((res) => {
        setNewUserInformation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function setNewUserInformation(newInformation) {
    setUserInformation(newInformation);
  }

  function setNewAccounts(newAccountsArray) {
    setUserInformation((prev) => {
      return { ...prev, accounts: newAccountsArray };
    });
  }

  return (
    <UserInformationContext.Provider
      value={{
        userInformation,
        setNewUserInformation,
        setNewAccounts,
      }}
    >
      {children}
    </UserInformationContext.Provider>
  );
}
