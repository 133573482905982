import { Link } from "react-router-dom";
import { useCompany } from "../../Context/companyContext";
import { useUser } from "../../Context/userContext";

export default function CompanyDashboardRow({ props, openRemovalModal }) {
  const { setCompany } = useCompany();
  console.log(props);

  return (
    <div className="account-row" onClick={() => {}}>
      <div className="account">{props.company}</div>
      <div className="div-block-26">
        <Link
          to={"/viewCompany/" + props._id}
          style={{ textDecoration: "none" }}
          onClick={() => {
            setCompany(props);
          }}
        >
          <div className="account-order-button">View Company Details</div>
        </Link>
        <Link
          to="/order"
          style={{ textDecoration: "none" }}
          onClick={() => {
            setCompany(props);
          }}
        >
          <div className="account-order-button">Place an order</div>
        </Link>
        <button
          to="/order"
          className="account-row-delete-button "
          style={{ width: 50 }}
          onClick={() => {
            openRemovalModal(props);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
}
