import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import AllProducts from "../../json/products.json";
import ProductRow from "../../Components/productRow";

import NavBar from "../components/navbar";
import { useShoppingCart } from "../../Context/shoppingCartContext";
import { useNavigate } from "react-router-dom";
import ShoppingCartHamburger from "../components/shoppingCartHamburger";

function Order() {
  ////////////////////////////////////
  //Setup all the variables for later use
  ////////////////////////////////////
  const navigate = useNavigate();
  let unfilteredProducts = [];
  const { cartItems, tier, setTier, resetCart } = useShoppingCart();

  AllProducts.map((headings) => {
    headings[Object.keys(headings)[0]].map((item) => {
      unfilteredProducts.push(item);
    });
  });

  const cartTotal = useMemo(
    () =>
      cartItems.reduce((total, item) => {
        const product = unfilteredProducts.find(
          (product) => product.name === item.name
        );
        return product[tier] * item.quantity + total;
      }, 0),
    [cartItems, AllProducts, tier]
  );
  const firstUpdate = useRef(true);
  const cart = useSelector((state) => state.cart.cart);
  const [reset, setReset] = useState(false);
  const tierState = useSelector((state) => state.cart.tier);
  const totalState = useSelector((state) => state.cart.totalCost);
  const [search, setSearch] = useState("");
  const [productTotals, setProductTotals] = useState(cart || []);
  let [tierNumber, setTierNumber] = useState(tierState || "None Selected");
  const [open, setOpen] = React.useState(false);
  let [productTotalOrderedPrice, setProductTotalOrderPrice] =
    useState(totalState);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const mainPanel = {
    width: "100%",
    overflowY: "scroll",
    backgroundColor: "#e3e3e3",
    marginTop: "30px",
  };

  ////////////////////////////////////
  //  Calculate the order on every re-render
  ////////////////////////////////////
  useEffect(() => {
    function calcTotal() {
      let addingPrice = 0;

      //Start adding the prices together
      //This will reach out the the whatTier function for the price
      Object.keys(productTotals).forEach((item) => {
        addingPrice =
          addingPrice +
          productTotals[item].ordered *
            whatTier(productTotals[item].productInfo);
      });

      //Get the price of the item, determined by what tier is selected
      function whatTier(product) {
        switch (tierNumber) {
          case "tierThreePrice":
            return product.tierThreePrice;
          case "tierFourPrice":
            return product.tierFourPrice;
          case "tierFivePrice":
            return product.tierFivePrice;
          default:
            return product.tierThreePrice;
        }
      }

      //Set the total ordered price
      setProductTotalOrderPrice(addingPrice);
    }

    //Run the function above to Calculate the total price. Basically everytime something is changed on the screen.
    calcTotal();
  }, [productTotals, tierNumber]);

  ////////////////////////////////////
  //Do not run the reload on the first render
  //Reload the page when the user presses an add to cart button
  ////////////////////////////////////
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      firstUpdate.current = false;
      return;
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.location.reload();
    }
  }, [reset]);

  ////////////////////////////////////
  //Read the products from the JSON file and create a bunch of rows to contain it.
  ////////////////////////////////////
  const allProductsForSearch = useMemo(() => {
    return AllProducts.map((headings) => {
      return headings[Object.keys(headings)[0]]
        .filter((item) => {
          if (search === "") {
            return item;
          } else if (
            item.name
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase())
          ) {
            return item;
          } else return null;
        })
        .map((item, index) => {
          return <ProductRow key={index} props={item} />;
        });
    });
  }, [cartItems]);

  ////////////////////////////////////
  //How many products are on the screen after we searched
  ////////////////////////////////////
  let numberOfMatches = 0;
  allProductsForSearch.forEach((match) => {
    numberOfMatches = numberOfMatches + match.length;
  });

  ////////////////////////////////////
  // Set search string
  ////////////////////////////////////
  const onEnter = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <>
      <NavBar />
      <section className="section-4">
        <div
          className="shop-cart-button-container"
          style={{ justifyContent: "center", paddingRight: 0 }}
        >
          <div style={{ paddingTop: 20 }}>
            <h3 style={{ textAlign: "center" }}>
              Total:{" "}
              {cartTotal.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                navigate("/checkout");
              }}
            >
              <label htmlFor="" className="field-label">
                Select the purchasing tier
              </label>
              <select
                id="tierSelection"
                name="tierSelection"
                data-name="tierSelection"
                required
                className="order-tier-select w-select"
                defaultValue={""}
                onChange={(e) => {
                  setTier(e.target.value);
                }}
              >
                <option disabled value="">
                  Select one...
                </option>
                <option value="tierThreePrice">Elite</option>
                <option value="tierFourPrice">Elite+</option>
                <option value="tierFivePrice">VIP</option>
              </select>
              <input
                type="submit"
                className="view-company-button"
                value="Checkout"
              />
            </form>
          </div>
        </div>
        <div
          id="w-node-c8697c73-dc5b-8256-6ee0-7762e7750989-cb8a664c"
          className="w-layout-layout wf-layout-layout"
        >
          <div
            id="w-node-fa22ae6c-52bf-2e5f-e4b3-5723fb552077-cb8a664c"
            className="w-layout-cell product-rows-container"
          >
            <div className="div-block-18">
              <h1 className="view-company-large-heading">Products</h1>
              <div className="div-block-19"></div>
            </div>
            {allProductsForSearch}
          </div>
          <div
            id="w-node-_5700196d-8176-3713-eb51-a4f6105aee68-cb8a664c"
            className="w-layout-cell cell-5"
          >
            <div className="div-block-18">
              <h1 className="view-company-large-heading">Order Overview</h1>
              <div className="div-block-19"></div>
            </div>
            <div className="product-in-cart-container">
              {cartItems.map((item, index) => {
                return (
                  <div key={index} className="product-in-cart-row">
                    <div className="product-in-cart-name">{item.name}: </div>
                    <div className="product-in-cart-quantity">
                      {item.quantity}
                    </div>
                  </div>
                );
              })}
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                data-wf-page-id="657c7eb3ce4c50f6cb8a664c"
                data-wf-element-id="47f5045b-6eee-b066-e0aa-fec39f953c46"
                onSubmit={(e) => {
                  e.preventDefault();
                  navigate("/checkout");
                }}
              >
                <div className="form-block-5 w-form" style={{ paddingTop: 40 }}>
                  <label htmlFor="" className="field-label">
                    Select the purchasing tier
                  </label>
                  <select
                    id="tierSelection"
                    name="tierSelection"
                    data-name="tierSelection"
                    required
                    className="order-tier-select w-select"
                    defaultValue={""}
                    onChange={(e) => {
                      setTier(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select one...
                    </option>
                    <option value="tierThreePrice">Elite</option>
                    <option value="tierFourPrice">Elite+</option>
                    <option value="tierFivePrice">VIP</option>
                  </select>
                </div>

                <div className="text-block-10">
                  Cart Total:{" "}
                  {cartTotal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>

                <input
                  className="view-company-button"
                  value="Checkout"
                  type="submit"
                />

                {/* <button
                  onClick={() => {
                    resetCart();
                  }}
                  className="view-company-button"
                >
                  Reset Cart
                </button> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Order;
