import HamburgerMenu from "./hamburger";

export default function NavBar() {
  return (
    <>
      <section className="nav-bar">
        <div className="logo-container">
          <img
            src={require("../../Images/Side-by-Side-white.png")}
            loading="lazy"
            alt=""
            className="image-4"
          />
        </div>
        <div className="nav-link-container">
          <a href="/userDashboard" className="nav-link">
            Home
          </a>
          {/* <a href="/schedule" className="nav-link">
            Events
          </a> */}
          <a href="/benefits" className="nav-link">
            Benefits
          </a>
          <a href="/fieldGuide" className="nav-link">
            Field Guide
          </a>
          <a href="/contact" className="nav-link">
            Contact Us
          </a>
        </div>
        <div className="hamburger-container">
          <HamburgerMenu />
        </div>
      </section>
    </>
  );
}
