import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { init } from "emailjs-com";
import { Link } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/navbar";
import CompanyOrderRow from "../components/companyOrderRow";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Line } from "react-chartjs-2";
import moment from "moment";
import { useCompany } from "../../Context/companyContext";
init("user_m9sQKY2UWwjnhd6strAmN");

function ViewCompany() {
  const navigate = useNavigate();
  const { setCompany } = useCompany();
  const [companyDetails, setCompanyDetails] = useState({});
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [highestDate, setHighestDate] = useState(0);
  let xAxis = [];
  let yAxis = [];
  let lastOrder = new Date();
  let lastOrderPrice = 0;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    axios
      .post("/api/v2/Merz/accounts/selectCompany", {
        id: window.location.pathname.split("/")[2],
      })

      .then((res) => {
        console.log(res.data);
        setCompanyDetails(res.data);
        setOrders(res.data.orders !== undefined ? res.data.orders : []);
        setLoading(false);

        if (res.data.orders !== undefined) {
          let total = 0;
          res.data.orders.forEach((item) => {
            total += Number(item[1]);
          });
          setTotal(total);

          const highestDate = res.data.orders.reduce((maxDate, item) => {
            const currentDate = new Date(item[2]);
            return currentDate > maxDate ? currentDate : maxDate;
          }, new Date(0));

          setHighestDate(highestDate);
        }
      });
  }, []);

  orders
    .slice(0, 24)
    .sort((a, b) => {
      return new Date(a[2]).getTime() - new Date(b[2]).getTime();
    })
    .map((order, index) => {
      if (
        lastOrder ==
        moment(order[2]).format("MMM") + " " + moment(order[2]).format("DD")
      ) {
        lastOrderPrice = lastOrderPrice + Number(order[1]);
        yAxis[index] = lastOrderPrice;
      } else {
        xAxis.push(
          moment(order[2]).format("MMM") + " " + moment(order[2]).format("YY")
        );
        yAxis.push(Number(order[1]));
      }

      lastOrder =
        moment(order[2]).format("MMM") + " " + moment(order[2]).format("DD");
      lastOrderPrice = Number(order[1]);
    });

  const chartData = {
    labels: xAxis,

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: Math.max(...yAxis),
            min: 0,
          },
        },
      ],
    },
    datasets: [
      {
        label: "Purchase Price",
        data: yAxis,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  return (
    <>
      <NavBar />
      <section className="notifications-bar">
        <div className="selection-bar">
          <h1 className="view-company-large-heading">
            {companyDetails.company}
          </h1>
        </div>
        <div className="cart-total">$9920.00</div>
      </section>
      <section>
        <div
          id="w-node-d8d88979-025c-366b-f114-2788e9a3b4fc-dddc6260"
          className="w-layout-layout quick-stack wf-layout-layout"
        >
          <div
            id="w-node-_965e181a-080e-f94d-cd49-306107cd13c6-dddc6260"
            className="w-layout-cell cell-2"
          >
            <div className="div-block-18">
              <h1 className="view-company-large-heading">Stats</h1>
              <div className="div-block-19"></div>
            </div>
            <div className="live-data-upper-column w-row">
              <div className="live-data-column w-col w-col-4">
                <div>
                  <h3 className="live-data-number">
                    {total.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </h3>
                  <div className="blue-text">Lifetime Total</div>
                </div>
              </div>
              <div className="live-data-column w-col w-col-4">
                <h3 className="live-data-number">
                  <div>{moment(highestDate).format("MM/DD/YY") || "none"}</div>
                </h3>
                <div className="green-text">Last Order Date</div>
              </div>
              <div className="live-data-column w-col w-col-4">
                <h3 className="live-data-number">{orders.length}</h3>
                <div className="purple-text">Total Orders</div>
              </div>
            </div>

            <div className="div-block-18">
              <h1 className="view-company-large-heading">Order History</h1>
              <div className="div-block-19"></div>
            </div>
            <div className="company-order-details-conatiner">
              <div className="company-order-details-conatiner">
                {orders
                  .sort((a, b) => new Date(a[2]) - new Date(b[2]))
                  .map((order, index) => (
                    <CompanyOrderRow key={index} order={order} />
                  ))}
              </div>
            </div>

            <div
              className="company-order-details-conatiner"
              style={{ paddingTop: 60 }}
            >
              <Line
                options={{
                  scales: {
                    yAxis: {
                      min: Math.min(...yAxis) - 2000,
                      max: Math.max(...yAxis) + 2000,
                    },
                  },
                }}
                data={chartData}
              />
            </div>
          </div>
          <div
            id="w-node-_2dcda79c-53f6-d22c-51b1-d1324d85085e-dddc6260"
            className="w-layout-cell cell"
          >
            <div className="div-block-18">
              <h1 className="view-company-large-heading">Quick Links</h1>
              <div className="div-block-19"></div>
            </div>
            <div className="div-block-20">
              <Link
                to="/order"
                className="nav-link lower-link"
                onClick={() => {
                  setCompany(companyDetails);
                }}
              >
                Place new order
              </Link>
              {/* <a href="#" className="nav-link lower-link">
                Edit Info
              </a> */}
            </div>
            <div className="div-block-18">
              <h1 className="view-company-large-heading">
                Billing Information
              </h1>
              <div className="div-block-19"></div>
            </div>
            <div className="div-block-15">
              <div className="text-block-9">
                {companyDetails.firstName} {companyDetails.lastName}
                <br />
                {companyDetails.purchaserPhone}
                <br />
                {companyDetails.email}
                <br />
                {companyDetails.shippingAddress}
                <br />
                {companyDetails.shippingCity}, {companyDetails.shippingState}{" "}
                {companyDetails.shippingZipcode}
              </div>
            </div>
            <div className="div-block-18">
              <h1 className="view-company-large-heading">
                Shipping Information
              </h1>
              <div className="div-block-19"></div>
            </div>
            <div className="div-block-15">
              <div className="text-block-9">
                {companyDetails.attn}
                <br />
                {companyDetails.shippingAddress}
                <br />
                {companyDetails.shippingCity}, {companyDetails.shippingState}{" "}
                {companyDetails.shippingZipcode}
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewCompany;
