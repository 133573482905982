import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
// import "./index.css";
// import "./CSS/msn-9ee90a.webflow.css";
// import "./CSS/normalize.css";
// import "./CSS/webflow.css";
import "./pages/css/msn-9ee90a.webflow.css";
import "./pages/css/normalize.css";
import "./pages/css/webflow.css";

// import Order from "./Components/Ordering/Order";
import Order from "./pages/order/indexNEW";
import Review from "./Components/Ordering/Review";
import AdminInputPurchase from "./Components/Tools/adminInputPurchase";
import Training from "./Components/Training/Training";
import DiscountTraining from "./Components/Training/discountTraining";
import Contact from "./pages/contact/index";
import Complete from "./Components/Ordering/complete";
import NewCompany from "./Components/Tools/newCompany";
import CreateNewAccount from "./pages/add-new-account";
import EditCompany from "./Components/Tools/editCompany";
import Users from "./Components/Tools/users";
import Accounts from "./Components/View/accounts";
import ViewCompany from "./pages/viewCompany/index.js";
// import AllPurchases from "./Components/View/allPurchases";
import AllPurchases from "./pages/all-purchases/index.js";
import ViewTransaction from "./Components/View/viewTransaction";
import AdminDashboard from "./pages/admin-dashboard/index";
// import UserDashboard from "./Components/Dashboard/dashboardUser";
// import Login from "./Components/App/login";
import Login from "./pages/login";
import SaltLogin from "./Components/App/saltLogin";
import Reducers from "./Components/reducers/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { createStore } from "@reduxjs/toolkit";
import Products from "./Components/View/viewProducts";
import UseAuth, { AuthProvider } from "./Components/App/auth.js";
import FieldGuide from "./pages/field-guide/index";
import Schedule from "./Components/View/schedule";
import ScheduleManager from "./Components/Tools/scheduleManager";
import Benefits from "./Components/Static/benefits";
import Test from "./Components/App/test";
import DownloadSpreadsheet from "./Components/Tools/downloadSpreadsheet";
import { ShoppingCartProvider } from "./Context/shoppingCartContext";
import { UserInformationProvider } from "./Context/userContext";
import { CompanyInformationProvider } from "./Context/companyContext";
import Checkout from "./pages/checkout";
import AddNewUser from "./pages/add-new-user";

//
//
//

import UserDashboard from "./pages/userDashboard/index.js";

import { LoginProvider } from "./Context/login";

document.title = "Medspa Network App";

function App() {
  const { createMemoryHistory } = require("history");
  const history = createMemoryHistory();

  const persistConfig = {
    key: "root",
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, Reducers);

  let store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  let persistor = persistStore(store);

  function RequireAuth({ children }) {
    let location = useLocation();
    const { authed } = UseAuth();

    return authed ? children : <Navigate to="/" state={{ from: location }} />;
  }

  function checkDashboard() {
    return localStorage.getItem("userType");
  }

  return (
    <LoginProvider>
      <AuthProvider>
        <UserInformationProvider>
          <CompanyInformationProvider>
            <ShoppingCartProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <BrowserRouter
                    location={history.location}
                    navigator={history}
                  >
                    <Routes>
                      <Route index element={<Login />} />
                      <Route path="saltlogin" element={<SaltLogin />} />
                      <Route path="addNewUser" element={<AddNewUser />} />

                      <Route
                        path="dashboard"
                        element={
                          checkDashboard() === "true" ? (
                            <RequireAuth redirectTo="/">
                              <AdminDashboard />
                            </RequireAuth>
                          ) : (
                            <RequireAuth redirectTo="/">
                              <UserDashboard />
                            </RequireAuth>
                          )
                        }
                      />

                      <Route
                        path="userDashboard"
                        element={
                          <RequireAuth redirectTo="/">
                            <UserDashboard />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="adminDashboard"
                        element={
                          <RequireAuth redirectTo="/">
                            <AdminDashboard />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="order"
                        element={
                          <RequireAuth redirectTo="/">
                            <Order />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="checkout"
                        element={
                          <RequireAuth redirectTo="/">
                            <Checkout />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="test"
                        element={
                          <RequireAuth redirectTo="/">
                            {" "}
                            <Test />{" "}
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="review"
                        element={
                          <RequireAuth redirectTo="/">
                            <Review />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="adminInputPurchase"
                        element={
                          <RequireAuth redirectTo="/">
                            <AdminInputPurchase />
                          </RequireAuth>
                        }
                      />

                      <Route path="contact" element={<Contact />} />

                      <Route
                        path="users"
                        element={
                          <RequireAuth redirectTo="/">
                            <Users />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="accounts"
                        element={
                          <RequireAuth redirectTo="/">
                            <Accounts />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="complete"
                        element={
                          <RequireAuth redirectTo="/">
                            <Complete />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="training"
                        element={
                          <RequireAuth redirectTo="/">
                            <Training />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="discounttraining"
                        element={
                          <RequireAuth redirectTo="/">
                            <DiscountTraining />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="newCompany"
                        element={
                          <RequireAuth redirectTo="/">
                            <CreateNewAccount />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="editCompany"
                        element={
                          <RequireAuth redirectTo="/">
                            <EditCompany />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="viewCompany/:id"
                        element={
                          <RequireAuth redirectTo="/">
                            <ViewCompany />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="allPurchases"
                        element={
                          <RequireAuth redirectTo="/">
                            <AllPurchases />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="viewTransaction/:id"
                        element={
                          <RequireAuth redirectTo="/">
                            <ViewTransaction />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="viewProducts"
                        element={
                          <RequireAuth redirectTo="/">
                            <Products />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="fieldGuide"
                        element={
                          <RequireAuth redirectTo="/">
                            <FieldGuide />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="schedule"
                        element={
                          <RequireAuth redirectTo="/">
                            <Schedule />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="scheduleManager"
                        element={
                          <RequireAuth redirectTo="/">
                            <ScheduleManager />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="benefits"
                        element={
                          <RequireAuth redirectTo="/">
                            <Benefits />
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="downloadspreadsheet/:id"
                        element={<DownloadSpreadsheet />}
                      />
                    </Routes>
                  </BrowserRouter>
                </PersistGate>
              </Provider>
            </ShoppingCartProvider>
          </CompanyInformationProvider>
        </UserInformationProvider>
      </AuthProvider>
    </LoginProvider>
  );
}

export default App;
