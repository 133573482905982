import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

function NewAccount() {
  // const ws = new WebSocket("wss://app.medspa.network/websockets");
  // ws.onopen = () => {};
  const User = useSelector((state) => state.User);
  const [details, setDetails] = useState({
    repName: User.repName,
    active: true,
    dateAdded: new Date(),
    shippingState: "AL",
  });
  const navigate = useNavigate();

  const mainPanel = {
    paddingBottom: "60px",
    width: "100%",
    backgroundColor: "#e3e3e3",
    overflowY: "scroll",
  };

  const enterNewCompany = (e) => {
    e.preventDefault();

    axios.post("/api/accounts/newCompany", details).then((res) => {
      console.log(res);
      axios
        .post("/api/accounts/attachCompany", {
          userId: User._id,
          companyId: res.data.insertedId,
        })
        .then((res) => {
          if (res.status == 200) navigate("/dashboard");
        });

      axios.post("/api/announcements/addAnnouncement", {
        details,
        importance: "Low",
      });
      ////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////////////

      axios
        .post(
          "https://www.medspanetwork.com/wp-json/wp/v2/users",
          {
            username: details.email,
            name: details.first_name + " " + details.last_name,
            first_name: details.first_name,
            last_name: details.last_name,
            nickname: details.first_name,
            email: details.email,
            password: details.password,
          },
          {
            auth: {
              username: "Keaton",
              password: "ksln Gv4h GJ2p sRCJ oir1 vNXJ",
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      var formData = new FormData();
      formData.append("image", event.target.files[0]);

      axios
        .post("/api/uploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setDetails({
            ...details,
            resaleCertPath: res.data.fileName,
          });
        });
    }
  };

  return (
    <div style={mainPanel}>
      <form
        onSubmit={(e) => enterNewCompany(e)}
        style={{
          margin: "auto",
          textAlign: "center",
          paddingBottom: "60px",
          maxWidth: "900px",
        }}
      >
        <div>
          <h3 className="inputFieldsHeaders">Purchasing Supervisor</h3>

          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="First Name"
            type="text"
            required
            id="firstName"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Last Name"
            type="text"
            required
            id="lastName"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Email"
            type="email"
            required
            id="email"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Purchaser Phone"
            type="tel"
            required
            id="purchaserPhone"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <h3 className="inputFieldsHeaders" style={{ paddingTop: "30px" }}>
            Company Details
          </h3>
          <p style={{ color: "black", textAlign: "left" }}>
            Shipping address must match customers record in Veeva to prevent
            shipping delays{" "}
          </p>
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Account #"
            type="text"
            required
            id="accountNumber"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Company"
            type="text"
            required
            id="company"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Company Email"
            type="text"
            required
            id="companyEmail"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Attn. Name"
            type="text"
            required
            id="attn"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Shipping Phone"
            type="tel"
            required
            id="shippingPhone"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Shipping Address"
            required
            id="shippingAddress"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="P/O Box"
            id="poBox"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />
          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Shipping City"
            required
            id="shippingCity"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          />

          <select
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            placeholder="Shipping State"
            required
            id="shippingState"
            onChange={(e) => {
              console.log(e.target.value);
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>

          <input
            style={{
              maxWidth: "900px",
              display: "block",
              backgroundColor: "white",
            }}
            className="newAccountFields"
            name="zip"
            type="number"
            placeholder="Shipping Zipcode"
            required
            id="shippingZipcode"
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.id]: e.target.value,
              });
            }}
          ></input>

          <h3 className="inputFieldsHeaders">Resale Certificate</h3>
          <input
            style={{
              color: "white",
              textAlign: "left",
              float: "left",
              marginBottom: "60px",
            }}
            type="file"
            name="file"
            onChange={(e) => {
              onImageChange(e);
            }}
          />

          <button
            style={{
              maxWidth: "900px",
              margin: "auto",
              marginTop: "30px",
              textAlign: "center",
            }}
            type="submit"
          >
            Submit new account
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewAccount;
