import axios from "axios";
import { getSemester } from "../../Functions/semester";

////////////////////////////////////
//  GetSocketData
////////////////////////////////////
export const GetSocketData = (message) =>
  new Promise((resolve, reject) => {
    axios
      .get("https://www.medspanetwork.com/api/announcements/getAnnouncements")
      .then((res) => {
        resolve({
          notifications: res.data,
          numberOfUsers: JSON.parse(message.data).size,
        });
      });
  });

////////////////////////////////////
//  GetDashboardData
////////////////////////////////////
export const GetDashboardData = () =>
  new Promise((resolve, reject) => {
    let semesterName = getSemester();
    let tier5 = 0;

    axios
      .post("/api/v2/Merz/dashboards/getDashboardData", {
        id: JSON.parse(localStorage.getItem("userId")),
      })
      .then((res) => {
        let total = 0;
        let avg = 0;

        res.data[1].forEach((purchase) => {
          total = purchase.shippingStatus === "Pending" ? total + 1 : total;
          avg = avg + Number(purchase.amount);
        });

        res.data[2].forEach((company) => {
          if (company[semesterName] > 15000) {
            tier5 = tier5 + 1;
          }
        });

        //Set All Data for Dashboard
        ////////////////////////////////////////////////////////////
        resolve({
          companiesList: res.data[2],
          announcements: res.data[3],
          avgSpend: Math.round(avg / res.data[1].length),
          nonShippedOrders: total,
          tier5: tier5,
          type: "setUser",
          TBMEmail: res.data[0].TBMEmail,
          TBMPhoneNumber: res.data[0].TBMPhoneNumber,
          repName: res.data[0].firstName + " " + res.data[0].lastname,
          userType: res.data[0].type,
          _id: String(res.data[0]._id),
        });
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        }
      });
  });

////////////////////////////////////
//  GetAnnouncements
////////////////////////////////////
export const GetAnnouncements = () =>
  new Promise((resolve, reject) => {
    axios
      .get("https://www.medspanetwork.com/api/announcements/getAnnouncements")
      .then((res) => {
        console.log(res);
        resolve(res.data.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
      });
  });

////////////////////////////////////
//  DeleteNotification
////////////////////////////////////
export const DeleteNotification = (id) =>
  new Promise((resolve, response) => {
    axios
      .delete("https://www.medspanetwork.com/api/announcements/delete", {
        data: { _id: id },
      })
      .then((res) => {
        resolve(true);
      });
  });

////////////////////////////////////
//  SelectCompany
////////////////////////////////////
export const SelectCompany = (e) => {
  axios
    .post("https://www.medspanetwork.com/api/accounts/selectCompany/", {
      id: e,
    })
    .then((res) => {
      return res.data;
    });
};
