import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { init } from "emailjs-com";
import "react-credit-cards/es/styles-compiled.css";
import axios from "axios";
import Cards from "react-credit-cards";
import Header from "../Header.js";
import "../App/hamburgers.css";
import ReviewInputField from "./components/reviewInputFields";
import DashboardNav from "../Dashboard/dashboardNav";
import { intToDollars } from "../Functions/intConversions";
import Flagging from "./TEST";
// import { SocketContext } from "../../sockets/websocket";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { borderLeft } from "@mui/system";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

init("user_m9sQKY2UWwjnhd6strAmN");

export default function Order() {
  ////////////////////////////////////
  // Setup Variables
  ////////////////////////////////////
  let navigate = useNavigate();
  let emailCart = [];
  let [OK, setOK] = useState("");
  const cart = useSelector((state) => state.cart.cart);
  const tier = useSelector((state) => state.cart.tier);
  const company = useSelector((state) => state.Company);
  const user = useSelector((state) => state.User);
  const totalCost = useSelector((state) => state.cart.totalCost);
  let [Understood, setUnderstood] = useState(false);
  const [billShip, setBillShip] = useState(false);
  const [buttonAvail, setButtonAvail] = useState(true);
  const [availableCredit, setAvailableCredit] = useState(company.credit || 0);
  const [creditApplied, setCreditApplied] = useState(false);
  const [newTotal, setNewTotal] = useState(0);
  const [focusField, setFocusField] = useState("name");

  // SET WARNNING
  const warning = Flagging(totalCost);

  // OPEN SOCKET
  // const socket = useContext(SocketContext);

  ////////////////////////////////////////////
  let orderingSupervisor = {
    firstName: ["firstName", "text", true],
    lastName: ["lastName", "text", true],
    email: ["purchaserEmail", "email", true],
    purchaserPhone: ["purchaserPhone", "tel", true],
  };

  let shippingDetails = {
    company: ["company", "text", true],
    accountNumber: ["accountNumber", "tel", true],
    email: ["email", "email", true],
    attn: ["attn", "text", true],
    shippingPhone: ["shippingPhone", "tel", true],
    shippingAddress: ["shippingAddress", "text", true],
    shippingCity: ["shippingCity", "text", true],
    shippingState: ["shippingState", "text", true],
    poBox: ["poBox", "text", false],
    shippingZipcode: ["shippingZipcode", "tel", true],
  };

  ////////////////////////////////////
  //  Change tier Number to an INT
  ////////////////////////////////////
  const getTierNumber = (tier) => {
    switch (tier) {
      case "tierThreePrice":
        return 3;
      case "tierFourPrice":
        return 4;
      case "tierFivePrice":
        return 5;
      default:
        break;
    }
  };

  ////////////////////////////////////
  //  set the ordering details
  ////////////////////////////////////
  const [details, setDetails] = useState({
    cost: totalCost.toFixed(2),
    expiry: "",
    cvc: "",
    number: "",
    tier: getTierNumber(tier),
    attn: company.attn,
    company: company.company,
    email: company.email,
    firstName: company.firstName,
    lastName: company.lastName,
    poBox: company.poBox,
    purchaserPhone: company.purchaserPhone,
    purchaserEmail: company.email,
    shippingAddress: company.shippingAddress,
    shippingCity: company.shippingCity,
    shippingPhone: company.shippingPhone,
    shippingState: company.shippingState,
    shippingZipcode: company.shippingZipcode,
    accountNumber: company.accountNumber,
    billingFirstName: "",
    billingLastName: "",
    repPhone: user.phone,
    repName: user.repName,
    repEmail: user.email,
    _id: company._id,
    shippingStatus: "Pending",
    extraDetails: "",
    followedRecommendations: !warning.warn,
    openingOrder: warning.openingOrder,
    warningMessage: warning.message,
  });

  ////////////////////////////////////
  //  useEffect
  ////////////////////////////////////
  useEffect(() => {
    //  Scroll to the top of the page on every re-render
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  ////////////////////////////////////
  //Setup the cart overview items
  ////////////////////////////////////
  const otherCart = Object.keys(cart).map((item, index) => {
    return cart[item] && cart[item].ordered !== 0 ? (
      <div key={index}>
        <div
          key={index}
          className="overviewRows"
          style={{
            backgroundColor: "transparent",
            borderBottom: "1px solid grey",
          }}
        >
          <div>
            <p style={{ margin: "5px" }}>{cart[item].productInfo.name}: </p>
          </div>
          <div>
            <p style={{ margin: "5px" }}>{cart[item].ordered}</p>
          </div>
        </div>
      </div>
    ) : (
      <div key={index}></div>
    );
  });

  ////////////////////////////////////
  //  TRANSACTION - Proccess the card
  ////////////////////////////////////
  const payment = (e) => {
    //Prevent the default submit action so that we can run the rest of the code below.
    //We also hide the button so people dont keep pressing it.
    // e.preventDefault();
    // setButtonAvail(false);
    let returnInfo = [];
    let returns = "";
    let emailCart = [];

    Object.keys(cart).forEach((item) => {
      const ItemTotal = intToDollars(
        cart[item].productInfo[tier] * cart[item].ordered
      );

      if (cart[item] !== 0) {
        emailCart.push(`<html>
        <table width="600" style="border:1px solid #333; margin: auto">
          <tr>
            <td align="center">Product</td>
            <td align="center">Amount Ordered</td>
            <td align="center">Product Price</td>
            <td align="center">Total</td>
          </tr>
          <tr>
            <td align="center" style="color: black">${item}</td>
            <td align="center" style="color: black">${cart[item].ordered}</td>
            <td align="center" style="color: black">${cart[item].productInfo[tier]}</td>
            <td align="center" style="color: black">${ItemTotal}</td>
          </tr>
        </table>
      </html>`);
      }
    });

    try {
      //Send Credit Card info to the node backend.
      console.log(cart);
      const cardDetails = {
        ...details,
        companyName: details.company,
        total: details.cost,
        my_html: emailCart.join(""),
      };

      const userDetails = {
        companyName: details.company,
        _id: company._id,
      };
      const redefinedCart = [];

      for (let index = 0; index < cart.length; index++) {
        let item = {
          name: cart[index].productInfo.name,
          description: cart[index].productInfo.name,
          quantity: cart[index].ordered,
          price:
            details.tier == 3
              ? cart[index].productInfo.tierThreePrice
              : details.tier == 4
              ? cart[index].productInfo.tierFourPrice
              : details.tier == 5
              ? cart[index].productInfo.tierFivePrice
              : cart[index].productInfo.tierThreePrice,
        };

        redefinedCart.push(item);
      }
      axios
        .post(`https://www.medspanetwork.com/api/purchase/gpo_purchase`, {
          cardDetails: cardDetails,
          userDetails: userDetails,
          price: details.cost,
        })
        .then((res) => {
          if (res.data.ok) {
            navigate("/complete");
          } else {
            setOK(res.data.err);
          }
        })
        .catch((res) => {
          setOK(res.data.err);
        });
    } catch (err) {
      //Failed to process card
      // errorEmail("Card Proccessing Error: " + err);
      // failedOrder(details, cart, err);
      setOK(err);
    }
  };

  ////////////////////////////////////
  //  Email Functions
  ////////////////////////////////////

  const orderSuccess = (details, cart) => {
    axios.post(`https://www.medspanetwork.com/api/accounts/credit`, {
      _id: company._id,
      availableCredit: availableCredit,
    });

    axios
      .post(`https://www.medspanetwork.com/api/orders/purchaseMongo`, {
        details: details,
        cart: cart,
      })
      .then((res) => {
        // setUpEmail();
        // socket.send("Purchase");
        setUpEmail();
      });
  };

  const failedOrder = (details, cart, returns) => {
    // socket.send("Failed");
    errorEmail();

    axios.post(`https://www.medspanetwork.com/api/orders/failedOrder`, {
      details: details,
      cart: cart,
      returnString: returns,
    });
  };

  ////////////////////////////////////
  //  CREATE and SEND ERROR email
  ////////////////////////////////////
  const errorEmail = (reasoning) => {
    //Use the EMAILJS api to send an email out to everyone
    window.emailjs.send("service_k7n5kjk", "template_5jsjvur", {
      reason: reasoning,
      repName: details.repName,
      repPhone: details.repPhone,
      repEmail: details.repEmail,
    });
  };

  ////////////////////////////////////
  //  CREATE and SEND emails out on purchase
  ////////////////////////////////////
  const setUpEmail = async () => {
    //Setup the HTML table for the products at the bottom of the email.

    Object.keys(cart).forEach((item) => {
      const ItemTotal = intToDollars(
        cart[item].productInfo[tier] * cart[item].ordered
      );

      if (cart[item] !== 0) {
        emailCart.push(`<html>
        <table width="600" style="border:1px solid #333; margin: auto">
          <tr>
            <td align="center">Product</td>
            <td align="center">Amount Ordered</td>
            <td align="center">Product Price</td>
            <td align="center">Total</td>
          </tr>
          <tr>
            <td align="center" style="color: black">${item}</td>
            <td align="center" style="color: black">${cart[item].ordered}</td>
            <td align="center" style="color: black">${cart[item].productInfo[tier]}</td>
            <td align="center" style="color: black">${ItemTotal}</td>
          </tr>
        </table>
      </html>`);
      }
    });

    //Use the EMAILJS api to send an email out to everyone
    window.emailjs
      .send("service_k7n5kjk", "template_tm1vdhl", {
        email_to: ["RSStratAccts@merz.com", details.repEmail, details.email],
        message: "There is a new order of: ",
        from_name: "Medspa Network Orders",
        reply_to: ["sales@medspanetwork.com", ""],
        to_name: "Medspa Network",
        my_html: emailCart.join(""),
        accountNumber: details.accountNumber,
        company: details.company,
        firstName: details.firstName,
        lastName: details.lastName,
        purchaserEmail: details.purchaserEmail,
        purchaserPhone: details.purchaserPhone,
        attn: details.attn,
        shippingPhone: details.shippingPhone,
        shippingAddress: details.shippingAddress,
        shippingCity: details.shippingCity,
        shippingState: details.shippingState,
        shippingZipcode: details.shippingZipcode,
        poBox: details.poBox,
        repEmail: details.repEmail,
        repPhone: details.repPhone,
        total: intToDollars(Number(totalCost)),
        extraDetails: details.extraDetails,
      })
      .then((res) => {
        axios.post("/api/admin/fixTotals", { _id: company._id }).then((res) => {
          navigate("/complete");
        });
      })
      // Handle errors
      .catch((err) =>
        //TODO make this better, error should show up on screen
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured: ",
          err
        )
      );
  };

  const inputFields = Object.keys(orderingSupervisor).map((field, index) => {
    return (
      <ReviewInputField
        key={index}
        companyValue={company[field]}
        detailsValue={details[field]}
        fieldName={field}
        setDetails={setDetails}
        details={details}
        required={orderingSupervisor[field][2]}
      />
    );
  });

  const shippingInputFields = Object.keys(shippingDetails).map(
    (field, index) => {
      return (
        <ReviewInputField
          key={index}
          companyValue={company[field]}
          detailsValue={details[field]}
          fieldName={field}
          setDetails={setDetails}
          details={details}
          required={shippingDetails[field][2]}
        />
      );
    }
  );

  const calculateNewTotal = (credit) => {
    let newTotal = totalCost - credit;
    if (newTotal < 0) {
      setAvailableCredit(Math.abs(newTotal));
      setNewTotal(0);
      setDetails((prevState) => ({ ...prevState, cost: 0 }));
    } else {
      setNewTotal(newTotal);
      setDetails((prevState) => ({ ...prevState, cost: newTotal }));
    }
  };

  const resetTotal = () => {
    setNewTotal(0);
    setDetails((prevState) => ({ ...prevState, cost: totalCost }));
  };

  const handleInputFocus = (e) => {
    setFocusField(e.target.name);
  };

  ////////////////////////////////////
  //  RENDER
  ////////////////////////////////////
  return (
    <div className="reviewDashboard">
      <Header style={{ height: "100%", position: "relative" }} />
      <div className="mainContainer">
        <DashboardNav />

        <div
          className="dashboardMainPanel scrollWindow"
          id="adminPanel"
          style={{ backgroundColor: "white" }}
        >
          <div className="breadcrumbBarContainer">
            <div className="breadcrumbBar">
              <Button
                color="primary"
                variant="contained"
                className="backButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Button>
              <p className="breadcrumb">/ Review Order</p>
            </div>
          </div>
          {window.innerWidth > 900 ? (
            <div className="contentContainer">
              <div className="columnLeft scrollWindow">
                {" "}
                <h2>Want to review the Shipping Details?</h2>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: "lightgrey",
                      color: "black",
                    }}
                  >
                    <Typography>Click to expand Shipping Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form
                      style={{ paddingBottom: "60px" }}
                      onSubmit={(e) => payment(e)}
                    >
                      <div>
                        <div> {inputFields}</div>
                        <div> {shippingInputFields}</div>
                      </div>
                    </form>
                  </AccordionDetails>
                </Accordion>
                {/* {warning.warn ? (
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                    }}
                  >
                    <Alert variant={"filled"} severity="warning">
                      {warning.message}
                    </Alert>
                  </div>
                ) : null} */}
                <form
                  style={{ paddingBottom: "60px" }}
                  onSubmit={(e) => payment(e)}
                >
                  <h3 className="inputFieldsHeaders">Credit Card Details</h3>

                  <div style={{ padding: "0px 0px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          if (e.target.checked === true) {
                            setBillShip(true);
                            setDetails({
                              ...details,
                              billingAddress: company.shippingAddress,
                              billingCity: company.shippingCity,
                              billingState: company.shippingState,
                              billingZip: company.shippingZipcode,
                            });
                          } else {
                            setBillShip(false);
                          }
                        }}
                      />
                      <p style={{ fontSize: "16px" }}>
                        Billing Address is same as Shipping Address
                      </p>
                    </div>
                    <div id="billingDetails" style={{ marginBottom: "10px" }}>
                      {billShip === false ? (
                        <div style={{ marginBottom: "30px", gap: "32px" }}>
                          <TextField
                            required
                            error={details.billingAddress ? false : true}
                            id={"billingAddress"}
                            sx={{
                              width: "100%",
                              margin: "auto",
                              marginBottom: "16px",
                            }}
                            label={"Billing Address"}
                            color={details.billingAddress ? "success" : "error"}
                            focused
                            variant="filled"
                            value={details.billingAddress}
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                          <TextField
                            required
                            error={details.billingCity ? false : true}
                            id={"billingCity"}
                            sx={{
                              width: "100%",
                              margin: "auto",
                              marginBottom: "16px",
                            }}
                            label={"Billing City"}
                            color={details.billingCity ? "success" : "error"}
                            focused
                            variant="filled"
                            value={details.billingCity}
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                          <TextField
                            required
                            error={details.billingState ? false : true}
                            id={"billingState"}
                            sx={{
                              width: "100%",
                              margin: "auto",
                              marginBottom: "16px",
                            }}
                            label={"Billing State"}
                            color={details.billingState ? "success" : "error"}
                            focused
                            variant="filled"
                            value={details.billingState}
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                          <TextField
                            required
                            error={details.billingZipCode ? false : true}
                            id={"billingZipCode"}
                            sx={{
                              width: "100%",
                              margin: "auto",
                              marginBottom: "16px",
                            }}
                            label={"Billing Zipcode"}
                            color={details.billingZipCode ? "success" : "error"}
                            focused
                            variant="filled"
                            value={details.billingZipCode}
                            onChange={(e) => {
                              setDetails({
                                ...details,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      ) : null}

                      <TextField
                        required
                        error={details.billingFirstName ? false : true}
                        id={"billingFirstName"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Billing First Name"}
                        color={details.billingFirstName ? "success" : "error"}
                        variant="filled"
                        value={details.billingFirstName}
                        name="name"
                        onFocus={(e) => {
                          handleInputFocus(e);
                        }}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        required
                        error={details.billingLastName ? false : true}
                        id={"billingLastName"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Billing Last Name"}
                        color={details.billingLastName ? "success" : "error"}
                        variant="filled"
                        value={details.billingLastName}
                        name="name"
                        onFocus={(e) => {
                          handleInputFocus(e);
                        }}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />

                      <TextField
                        required
                        error={details.number ? false : true}
                        id={"number"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Card Number"}
                        color={details.number ? "success" : "error"}
                        variant="filled"
                        value={details.number}
                        name="number"
                        onFocus={(e) => {
                          handleInputFocus(e);
                        }}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />

                      <TextField
                        required
                        error={details.expiry ? false : true}
                        id={"expiry"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Card Exp Date"}
                        color={details.expiry ? "success" : "error"}
                        variant="filled"
                        value={details.expiry}
                        name="expiry"
                        onFocus={(e) => {
                          handleInputFocus(e);
                        }}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        required
                        error={details.cvc ? false : true}
                        id={"cvc"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Card cvc"}
                        color={details.cvc ? "success" : "error"}
                        name="cvc"
                        onFocus={(e) => {
                          handleInputFocus(e);
                        }}
                        variant="filled"
                        value={details.cvc}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        id={"extraDetails"}
                        sx={{
                          width: "100%",
                          margin: "auto",
                          marginBottom: "16px",
                        }}
                        label={"Extra Requests for Order"}
                        color={"primary"}
                        focused
                        variant="filled"
                        value={details.extraDetails}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.id]: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div style={{ width: "100%" }} className="cloumnRight">
                <div className="creditCardContainer">
                  <div className="creditCardDetailsArea">
                    <Cards
                      focused={focusField}
                      cvc={details.cvc}
                      expiry={details.expiry}
                      name={
                        details.billingFirstName + " " + details.billingLastName
                      }
                      number={details.number}
                    />
                  </div>
                  <div className="recieptDetails">{otherCart}</div>
                  <h1
                    style={{
                      marginTop: "32px",
                      textAlign: "right",
                      margin: "auto",
                      width: "75%",
                    }}
                  >
                    Order Total: {intToDollars(totalCost)}
                  </h1>
                  {creditApplied ? (
                    <h1 style={{ marginTop: "0px" }}>
                      Amount Due After Credit Applied: {intToDollars(newTotal)}
                    </h1>
                  ) : null}
                  <div
                    style={{
                      marginTop: "64px",
                    }}
                  >
                    <h1 style={{ color: "#d2282e" }}>{OK}</h1>

                    {buttonAvail ? (
                      <div>
                        {Understood ? (
                          <Box
                            sx={{
                              width: "100%",
                              margin: "auto",
                              marginBottom: "16px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              onClick={(e) => {
                                payment();
                              }}
                            >
                              Process Purchase
                            </Button>
                          </Box>
                        ) : (
                          <div
                            className="purchasesArea"
                            style={{ margin: "auto" }}
                          >
                            {details.billingFirstName &&
                            details.billingLastName &&
                            details.number &&
                            details.expiry ? (
                              <div>
                                <h3 style={{ textAlign: "center" }}>
                                  I have notified the account that this
                                  transaction will show up as "Medspa Network"
                                  on their charges.
                                </h3>

                                <Button
                                  variant={"contained"}
                                  color="primary"
                                  onClick={() => {
                                    setUnderstood(true);
                                  }}
                                >
                                  Acknowledge
                                </Button>
                              </div>
                            ) : (
                              <Button variant={"outlined"} color="primary">
                                Please Complete Billing Details
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Button color="primary" variant="contained">
                        Running Transaction
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bigBox">
              <div
                style={{
                  maxWidth: "100%",
                  padding: "10px",
                  color: "black",
                  gap: "32px",
                }}
              >
                {/* //Form Start
                 */}
                <div
                  style={{
                    maxWidth: "100%",
                    padding: "0px 16px",
                    zIndex: "99",
                    marginBottom: "64px",
                  }}
                  className="scrollWindow"
                >
                  <h2>Want to review the Shipping Details?</h2>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        backgroundColor: "lightgrey",
                        color: "black",
                      }}
                    >
                      <Typography>Click to expand Shipping Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <form
                        style={{ paddingBottom: "60px" }}
                        onSubmit={(e) => payment(e)}
                      >
                        <div>
                          <div> {inputFields}</div>
                          <div> {shippingInputFields}</div>
                        </div>
                      </form>
                    </AccordionDetails>
                  </Accordion>
                  {warning.warn ? (
                    <div
                      style={{
                        width: "100%",
                        margin: "auto",
                      }}
                    >
                      <Alert variant={"filled"} severity="warning">
                        {warning.message}
                      </Alert>
                    </div>
                  ) : null}
                  <form
                    style={{ paddingBottom: "60px" }}
                    onSubmit={(e) => payment(e)}
                  >
                    <h3 className="inputFieldsHeaders">Credit Card Details</h3>

                    <div style={{ padding: "0px 0px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setBillShip(true);
                              setDetails({
                                ...details,
                                billingAddress: company.shippingAddress,
                                billingCity: company.shippingCity,
                                billingState: company.shippingState,
                                billingZip: company.shippingZipcode,
                              });
                            } else {
                              setBillShip(false);
                            }
                          }}
                        />
                        <p style={{ fontSize: "16px" }}>
                          Billing Address is same as Shipping Address
                        </p>
                      </div>
                      <div id="billingDetails" style={{ marginBottom: "10px" }}>
                        {billShip === false ? (
                          <div style={{ marginBottom: "30px", gap: "32px" }}>
                            <TextField
                              required
                              error={details.billingAddress ? false : true}
                              id={"billingAddress"}
                              sx={{
                                width: "100%",
                                margin: "auto",
                                marginBottom: "16px",
                              }}
                              label={"Billing Address"}
                              color={
                                details.billingAddress ? "success" : "error"
                              }
                              focused
                              variant="filled"
                              value={details.billingAddress}
                              onChange={(e) => {
                                setDetails({
                                  ...details,
                                  [e.target.id]: e.target.value,
                                });
                              }}
                            />
                            <TextField
                              required
                              error={details.billingCity ? false : true}
                              id={"billingCity"}
                              sx={{
                                width: "100%",
                                margin: "auto",
                                marginBottom: "16px",
                              }}
                              label={"Billing City"}
                              color={details.billingCity ? "success" : "error"}
                              focused
                              variant="filled"
                              value={details.billingCity}
                              onChange={(e) => {
                                setDetails({
                                  ...details,
                                  [e.target.id]: e.target.value,
                                });
                              }}
                            />
                            <TextField
                              required
                              error={details.billingState ? false : true}
                              id={"billingState"}
                              sx={{
                                width: "100%",
                                margin: "auto",
                                marginBottom: "16px",
                              }}
                              label={"Billing State"}
                              color={details.billingState ? "success" : "error"}
                              focused
                              variant="filled"
                              value={details.billingState}
                              onChange={(e) => {
                                setDetails({
                                  ...details,
                                  [e.target.id]: e.target.value,
                                });
                              }}
                            />
                            <TextField
                              required
                              error={details.billingZip ? false : true}
                              id={"billingZip"}
                              sx={{
                                width: "100%",
                                margin: "auto",
                                marginBottom: "16px",
                              }}
                              label={"Billing Zipcode"}
                              color={details.billingZip ? "success" : "error"}
                              focused
                              variant="filled"
                              value={details.billingZip}
                              onChange={(e) => {
                                setDetails({
                                  ...details,
                                  [e.target.id]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ) : null}

                        <TextField
                          required
                          error={details.billingFirstName ? false : true}
                          id={"billingFirstName"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Billing First Name"}
                          color={details.billingFirstName ? "success" : "error"}
                          variant="filled"
                          value={details.billingFirstName}
                          name="name"
                          onFocus={(e) => {
                            handleInputFocus(e);
                          }}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />
                        <TextField
                          required
                          error={details.billingLastName ? false : true}
                          id={"billingLastName"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Billing Last Name"}
                          color={details.billingLastName ? "success" : "error"}
                          variant="filled"
                          value={details.billingLastName}
                          name="name"
                          onFocus={(e) => {
                            handleInputFocus(e);
                          }}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />

                        <TextField
                          required
                          error={details.cardNumber ? false : true}
                          id={"cardNumber"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Card Number"}
                          color={details.cardNumber ? "success" : "error"}
                          variant="filled"
                          value={details.cardNumber}
                          name="number"
                          onFocus={(e) => {
                            handleInputFocus(e);
                          }}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />

                        <TextField
                          required
                          error={details.expDate ? false : true}
                          id={"expDate"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Card Exp Date"}
                          color={details.expDate ? "success" : "error"}
                          variant="filled"
                          value={details.expDate}
                          name="expiry"
                          onFocus={(e) => {
                            handleInputFocus(e);
                          }}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />
                        <TextField
                          required
                          error={details.cvv ? false : true}
                          id={"cvv"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Card CVV"}
                          color={details.cvv ? "success" : "error"}
                          name="cvc"
                          onFocus={(e) => {
                            handleInputFocus(e);
                          }}
                          variant="filled"
                          value={details.cvv}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />
                        <TextField
                          id={"extraDetails"}
                          sx={{
                            width: "100%",
                            margin: "auto",
                            marginBottom: "16px",
                          }}
                          label={"Extra Requests for Order"}
                          color={"primary"}
                          focused
                          variant="filled"
                          value={details.extraDetails}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              [e.target.id]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      paddingBottom: "10px",
                      borderBottom: "solid white 2px",
                      // borderLeft: "solid lightgrey 2px",
                      backgroundColor: "white",
                      position: "relative",
                      backgroundColor: "white",
                      color: "black",
                      border: "black 1px solid",
                      padding: "32px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        marginTop: "32px",
                        maxWidth: "100%",
                        margin: "auto",
                        maxHeight: "200px",
                        overflowY: "scroll",
                      }}
                    >
                      {otherCart}
                    </div>
                    <h1
                      style={{
                        marginTop: "32px",
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      Order Total: {intToDollars(totalCost)}
                    </h1>
                    {creditApplied ? (
                      <h1 style={{ marginTop: "0px" }}>
                        Amount Due After Credit Applied:{" "}
                        {intToDollars(newTotal)}
                      </h1>
                    ) : null}
                    <div
                      style={{
                        marginTop: "64px",
                        width: "100%",
                      }}
                    >
                      <h1 style={{ color: "#d2282e" }}>{OK}</h1>

                      {buttonAvail ? (
                        <div>
                          {Understood ? (
                            <Box
                              sx={{
                                width: "100%",
                                margin: "auto",
                                marginBottom: "16px",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                onClick={(e) => {
                                  payment();
                                }}
                              >
                                Process Purchase
                              </Button>
                            </Box>
                          ) : (
                            <div
                              className="purchasesArea"
                              style={{ margin: "auto" }}
                            >
                              {details.billingFirstName &&
                              details.billingLastName &&
                              details.cardNumber &&
                              details.expDate ? (
                                <div>
                                  <h3 style={{ textAlign: "center" }}>
                                    I have notified the account that this
                                    transaction will show up as "Medspa Network"
                                    on their charges.
                                  </h3>

                                  <Button
                                    variant={"contained"}
                                    color="primary"
                                    onClick={() => {
                                      setUnderstood(true);
                                    }}
                                  >
                                    Acknowledge
                                  </Button>
                                </div>
                              ) : (
                                <Button variant={"outlined"} color="primary">
                                  Please Complete Billing Details
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <Button color="primary" variant="contained">
                          Running Transaction
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
