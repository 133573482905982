import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LOGO from "../../Images/TRANSPARENT2.png";
import UseAuth from "../../Components/App/auth";
import { useUser } from "../../Context/userContext";

export default function Login() {
  const [username, setUserName] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState("");
  const [buttonAvail, setButtonAvail] = useState(true);
  const [warning, setWarning] = useState("");
  const navigate = useNavigate();
  const auth = UseAuth();
  const { setNewUserInformation } = useUser();

  useEffect(() => {
    if (username) {
      localStorage.setItem("username", username);
    }
  }, [username]);

  async function loginUser(e) {
    e.preventDefault();
    setButtonAvail(false);
    try {
      const res = await axios.post("/api/v2/Merz/accounts/merz-login", {
        userName: username,
        password: password,
      });
      console.log(res.data);
      if (res.status === 200) {
        if (res.data.return.active !== false) {
          localStorage.setItem(
            "userType",
            JSON.stringify(res.data.return.type)
          );
          localStorage.setItem("userId", JSON.stringify(res.data.return._id));
          localStorage.setItem("authToken", res.data.token);
          setNewUserInformation(res.data.return);

          auth.login();

          console.log(res.data.return);

          if (res.data.return.type === "true") {
            navigate("/adminDashboard");
          } else {
            navigate("/userDashboard");
          }
        } else {
          setButtonAvail(false);
          setWarning(
            "Your account is locked, please contact your Regional Director."
          );
        }
      }
    } catch (err) {
      console.log(err);
      setButtonAvail(true);
      setWarning("An error occurred while logging in. Please try again.");
    }
  }

  return (
    <div
      className=""
      style={{
        padding: "10px",
        maxWidth: "100vw",
        width: "100vw",
        background: "radial-gradient(white, rgba(150,150,150,1)",
        height: "100vh",
      }}
    >
      <div style={{ margin: "auto", textAlign: "center" }}>
        <img
          alt="Medspa Network Logo"
          src={LOGO}
          style={{
            maxWidth: "500px",
            width: "100%",
            margin: "auto",
            textAlign: "center",
          }}
        />
      </div>

      <form
        onSubmit={(e) => loginUser(e)}
        style={{ width: "100%", maxWidth: "900px", margin: "auto" }}
      >
        <label>
          <input
            className="loginOrderInputFields"
            type="text"
            value={username}
            placeholder="Email"
            onChange={(e) => setUserName(e.target.value.toLowerCase())}
          />
        </label>
        <label>
          <input
            style={{ marginTop: "45px" }}
            className="loginOrderInputFields"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value.replace(/-/g, ""));
            }}
          />
        </label>
        {warning !== "" ? (
          <>
            <div style={{ color: "red" }}>{warning}</div>
            <div>
              <a href="tel:7728280628">Or Call Tech Support</a>
            </div>
          </>
        ) : null}
        <div style={{ marginTop: "30px" }}>
          {buttonAvail ? (
            <button className="loginButton" type="submit">
              Login
            </button>
          ) : (
            <button
              className="loginButton"
              style={{ backgroundColor: "grey" }}
              type="submit"
            >
              Processing
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
