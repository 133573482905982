import React, { useMemo, useEffect, useCallback } from "react";
import NavBar from "../components/navbar";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../Context/companyContext";
import { useShoppingCart } from "../../Context/shoppingCartContext";
import AllProducts from "../../json/products.json";
import { useState } from "react";
import axios from "axios";
import { useUser } from "../../Context/userContext";
import cart from "../../Components/reducers/store";

function Checkout() {
  const navigate = useNavigate();
  const company = useCompany().companyInformation;
  const { cartItems, tier } = useShoppingCart();
  const [warning, setWarning] = useState("");
  const { userInformation } = useUser();
  console.log(userInformation);

  // SCROLL TO TOP OF PAGE ON LOAD
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  //SETUP CART VARIABLES
  let unfilteredProducts = [];

  AllProducts.map((headings) => {
    headings[Object.keys(headings)[0]].map((item) => {
      unfilteredProducts.push(item);
    });
  });

  const cartTotal = useMemo(
    () =>
      cartItems.reduce((total, item) => {
        const product = unfilteredProducts.find(
          (product) => product.name === item.name
        );
        return product[tier] * item.quantity + total;
      }, 0),
    [cartItems, AllProducts, tier]
  );

  //SETUP ORDER VARIABLES
  const [details, setDetails] = useState({
    cost: cartTotal.toFixed(2),
    ...company,
  });

  const handleCheckbox = useCallback((e) => {
    if (e.target.checked) {
      setDetails((prevState) => ({
        ...prevState,
        billingFirstName: details.firstName,
        billingLastName: details.lastName,
        billingAddress: details.shippingAddress,
        billingCity: details.shippingCity,
        billingState: details.shippingState,
        billingZipCode: details.shippingZipcode,
      }));
    } else {
      setDetails((prevState) => ({
        ...prevState,
        billingFirstName: "",
        billingLastName: "",
        billingAddress: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
      }));
    }
  });

  const handleAgreement = useCallback((e) => {
    setDetails((prevState) => ({ ...prevState, agreement: e.target.checked }));
  });

  function handleSubmit(e) {
    e.preventDefault();
    let emailCart = [];
    setWarning("Processing Card...");

    cartItems.forEach((item) => {
      const ItemTotal = (item[tier] * item.quantity).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      emailCart.push(`<html>
        <table width="600" style="border:1px solid #333; margin: auto">
          <tr>
            <td align="center">Product</td>
            <td align="center">Amount Ordered</td>
            <td align="center">Product Price</td>
            <td align="center">Total</td>
          </tr>
          <tr>
            <td align="center" style="color: black">${item.name}</td>
            <td align="center" style="color: black">${item.quantity}</td>
            <td align="center" style="color: black">${item[tier]}</td>
            <td align="center" style="color: black">${ItemTotal}</td>
          </tr>
        </table>
      </html>`);
    });

    try {
      const redefinedCart = [];

      for (let index = 0; index < cartItems.length; index++) {
        let item = {
          name: cartItems[index].name,
          description: cartItems[index].name,
          quantity: cartItems[index].quantity,
          price: cartItems[index][tier],
        };

        redefinedCart.push(item);
      }

      axios
        .post(`/api/v2/Merz/purchase/gpo-purchase`, {
          ...details,
          cart: cart,
          event: "Merz GPO Purchase",
          companyDetails: company,
          tier: tier,
          officeId: company._id,
          my_html: emailCart.join(" "),
          repEmail: userInformation.TBMEmail,
          repPhone: userInformation.TBMPhoneNumber,
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status === "fail") {
            setWarning(response.data.message);
            return;
          }

          navigate("/complete", { replace: true });
        })
        .catch(() => {});
    } catch (err) {
      console.log(err);
      // Failed to process card
    }
  }

  return (
    <div>
      <NavBar />
      <div className="section" style={{ position: "inherit" }}>
        <div className="w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div
              id="w-node-b8f24944-ee26-a454-4b2d-7b9a5d77515b-6b7f73e7"
              className="w-layout-layout quick-stack-4 wf-layout-layout"
            >
              <div
                id="w-node-_0609bfd9-269d-b28a-16c5-b17b131f4d7a-6b7f73e7"
                className="w-layout-cell cell-6"
              >
                <div className="checkout-infor-entry-container">
                  <div className="section-heading">
                    <h1 className="view-company-large-heading">
                      Shipping Information
                    </h1>
                    <div className="div-block-19"></div>
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="firstName"
                      data-name="firstName"
                      defaultValue={details.firstName}
                      id="firstName"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="lastName"
                      data-name="lastName"
                      defaultValue={details.lastName}
                      id="lastName"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="Address" className="form-label">
                      Address
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="Address"
                      data-name="Address"
                      defaultValue={details.shippingAddress}
                      id="Address"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          shippingAddress: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container city-state-zip">
                    <div>
                      <label htmlFor="shippingCity" className="form-label">
                        City
                      </label>
                      <input
                        required
                        type="text"
                        className="checkout-form-input w-input"
                        maxLength="256"
                        name="shippingCity"
                        data-name="shippingCity"
                        defaultValue={details.shippingCity}
                        id="shippingCity"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            shippingCity: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="shippingState" className="form-label">
                        State (**)
                      </label>
                      <input
                        required
                        type="text"
                        className="checkout-form-input w-input"
                        maxLength="2"
                        name="shippingState"
                        data-name="shippingState"
                        defaultValue={details.shippingState}
                        id="shippingState"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            shippingState: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="shippingZipcode" className="form-label">
                        Zip Code
                      </label>
                      <input
                        required
                        type="number"
                        className="checkout-form-input w-input"
                        maxLength="5"
                        name="shippingZipcode"
                        data-name="shippingZipcode"
                        defaultValue={details.shippingZipcode}
                        id="shippingZipcode"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            shippingZipcode: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-block-23">
                  <div className="section-heading">
                    <h1 className="view-company-large-heading">
                      Billing Information
                    </h1>
                    <div className="div-block-19"></div>
                  </div>
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      data-name="Checkbox"
                      className="w-checkbox-input"
                      onChange={(e) => {
                        handleCheckbox(e);
                      }}
                    />
                    <span className="w-form-label" htmlFor="checkbox">
                      Billing information is the same as shipping
                    </span>
                  </label>
                  <div className="form-input-field-container">
                    <label htmlFor="billingFirstName" className="form-label">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="billingFirstName"
                      data-name="billingFirstName"
                      defaultValue={details.billingFirstName}
                      id="billingFirstName"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          billingFirstName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="billingLastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="billingLastName"
                      data-name="billingLastName"
                      defaultValue={details.billingLastName}
                      id="billingLastName"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          billingLastName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="billingAddress" className="form-label">
                      Address
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="billingAddress"
                      data-name="billingAddress"
                      defaultValue={details.billingAddress}
                      id="billingAddress"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          billingAddress: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container city-state-zip">
                    <div>
                      <label htmlFor="billingCity" className="form-label">
                        City
                      </label>
                      <input
                        required
                        type="text"
                        className="checkout-form-input w-input"
                        maxLength="256"
                        name="billingCity"
                        data-name="billingCity"
                        defaultValue={details.billingCity}
                        id="billingCity"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            billingCity: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="billingState" className="form-label">
                        State (**)
                      </label>
                      <input
                        required
                        type="text"
                        className="checkout-form-input w-input"
                        maxLength="256"
                        name="billingState"
                        data-name="billingState"
                        defaultValue={details.billingState}
                        id="billingState"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            billingState: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="billingZipCode" className="form-label">
                        Zip Code
                      </label>
                      <input
                        required
                        type="number"
                        className="checkout-form-input w-input"
                        maxLength="256"
                        name="billingZipCode"
                        data-name="billingZipCode"
                        defaultValue={details.billingZipCode}
                        id="billingZipCode"
                        onChange={(e) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            billingZipCode: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-block-23">
                  <div className="section-heading">
                    <h1 className="view-company-large-heading">
                      Credit Card Information
                    </h1>
                    <div className="div-block-19"></div>
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="number" className="form-label">
                      Number
                    </label>
                    <input
                      required
                      type="number"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="number"
                      data-name="number"
                      defaultValue={details.number}
                      id="number"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          number: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="expiry" className="form-label">
                      Expiration Date (mmyyyy)
                    </label>
                    <input
                      required
                      type="number"
                      className="checkout-form-input w-input"
                      maxLength={6}
                      minLength={6}
                      name="expiry"
                      data-name="expiry"
                      defaultValue={details.expiry}
                      id="expiry"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          expiry: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="cvc" className="form-label">
                      CVC
                    </label>
                    <input
                      required
                      type="number"
                      className="checkout-form-input w-input"
                      maxLength="4"
                      name="cvc"
                      data-name="cvc"
                      defaultValue={details.cvc}
                      id="cvc"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          cvc: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="div-block-23">
                  <div className="section-heading">
                    <h1 className="view-company-large-heading">
                      Extra Information
                    </h1>
                    <div className="div-block-19"></div>
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="currentITM" className="form-label">
                      Current ITM
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="currentITM"
                      data-name="currentITM"
                      defaultValue={details.currentITM}
                      id="currentITM"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          currentITM: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="currentPDS" className="form-label">
                      Current PDS
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="currentPDS"
                      data-name="currentPDS"
                      defaultValue={details.currentPDS}
                      id="currentPDS"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          currentPDS: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="currentRD" className="form-label">
                      Current RD
                    </label>
                    <input
                      required
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="currentRD"
                      data-name="currentRD"
                      defaultValue={details.currentRD}
                      id="currentRD"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          currentRD: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-input-field-container">
                    <label htmlFor="extraInformation" className="form-label">
                      Extra information
                    </label>
                    <input
                      type="text"
                      className="checkout-form-input w-input"
                      maxLength="256"
                      name="extraInformation"
                      data-name="extraInformation"
                      placeholder="Ex. Please ship on friday for a monday delivery. "
                      id="extraInformation"
                      onChange={(e) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          extraDetails: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                id="w-node-_5700196d-8176-3713-eb51-a4f6105aee68-cb8a664c"
                className="w-layout-cell cell-7 "
              >
                <div className="div-block-18">
                  <h1 className="view-company-large-heading">Order Overview</h1>
                  <div className="div-block-19"></div>
                </div>
                <div
                  className="product-in-cart-container"
                  style={{ height: "auto" }}
                >
                  {cartItems.map((item, index) => {
                    return (
                      <div key={index} className="product-in-cart-row">
                        <div className="product-in-cart-name">
                          {item.name}:{" "}
                        </div>
                        <div className="product-in-cart-quantity">
                          {item.quantity}
                        </div>
                      </div>
                    );
                  })}

                  <div className="text-block-10">
                    Cart Total:{" "}
                    {cartTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </div>
                  <label className="w-checkbox">
                    <input
                      required
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      data-name="Checkbox"
                      className="w-checkbox-input"
                      onChange={(e) => {
                        handleAgreement(e);
                      }}
                    />
                    <span className="w-form-label" htmlFor="checkbox">
                      I have reminded the account that they will be billed by
                      Medspa Network and not by Merz.
                    </span>
                  </label>
                  {warning}
                  <input
                    type="submit"
                    defaultValue="Submit"
                    data-wait="Please wait..."
                    className="submit-button-2 w-button"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
