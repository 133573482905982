import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import Header from "../../Components/Header";
import DashboardNav from "../../Components/Dashboard/dashboardNav";
import AccountsList from "../../Components/Dashboard/components/accountsList";
import GOOD from "../../Images/alert-good-level.png";
import MINOR from "../../Images/alert-low-level.png";
import WARNING from "../../Images/alert-warning-level.png";
import MAJOR from "../../Images/alert-high-level.png";
import InfoSquare from "../../Components/Dashboard/components/infoSquare";
import { intToDollars } from "../../Components/Functions/intConversions";
import {
  GetDashboardData,
  SelectCompany,
  DeleteNotification,
} from "../../Components/Dashboard/services/services";

import "../../Components/Dashboard/styles.css";
import axios from "axios";

function Dashboard() {
  const dispatch = useDispatch();
  const [deleted, setDeleted] = useState(false);
  const [data, setData] = useState({
    avgSpend: 0,
    nonShippedOrders: 0,
    tier5: 0,
    notifications: [],
    liveUsers: 0,
    numberOfUsers: 0,
    companiesList: [],
  });

  //   useEffect(() => {
  //     axios.post("/api/v2/admin/addSemesterTotals").then((res) => {
  //       console.log(res);
  //     });
  //   }, []);

  //Recall for notifications if one is deleted
  ////////////////////////////////////////////////////////////
  useEffect(() => {
    axios
      .get("/api/v2/Merz/accounts/getAllAccounts")
      .then((res) => {
        if (res.data.status === "success") {
          setData((prevState) => ({
            ...prevState,
            companiesList: res.data.data,
          }));
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [deleted]);

  ////////////////////////////////////
  //  Send Company details to Redux
  ////////////////////////////////////
  const selectCompany = (e) => {
    SelectCompany(e).then((response) => {
      dispatch({
        type: "selectCompany",
        data: response,
      });
    });
  };

  ////////////////////////////////////
  //  Delete Notifications details to Redux
  ////////////////////////////////////
  const deleteNotificaiton = (id, index) => {
    setData((prevState) => ({
      ...prevState,
      notifications: prevState.notifications.filter(
        (x, indx) => indx !== index
      ),
    }));
    DeleteNotification(id).then((res) => {
      setDeleted(!deleted);
    });
  };

  ////////////////////////////////////
  //  Build Companies list for display
  ////////////////////////////////////
  const companies = data.companiesList
    .sort((a, b) => (a.company > b.company ? 1 : -1))
    .map((company, index) => {
      return (
        <AccountsList
          key={index}
          company={company}
          index={index}
          selectCompany={selectCompany}
        />
      );
    });

  const notificationsArea = data.notifications.map((item, index) => {
    let src;
    switch (item.importance) {
      case "Good":
        src = GOOD;
        break;

      case "Low":
        src = MINOR;
        break;

      case "Warn":
        src = WARNING;
        break;

      case "High":
        src = MAJOR;
        break;

      default:
        break;
    }

    return (
      <div key={index} className="notification">
        <div
          className="announcementRow"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <img alt="" src={src} style={{ width: "20px", height: "20px" }} />
          <p style={{ color: "black" }}>{item.message}</p>
        </div>
        <button
          onClick={() => {
            deleteNotificaiton(item._id, index);
          }}
          style={{
            width: "50px",
            borderRadius: "5px",
            backgroundColor: "grey",
          }}
        >
          OK
        </button>
      </div>
    );
  });

  ////////////////////////////////////
  //  RENDER
  ////////////////////////////////////

  return (
    <div style={{ maxHeight: "100vh", overflowY: "hidden" }}>
      <Header style={{ height: "100%", position: "relative" }} />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          height: "100vh",
        }}
      >
        <DashboardNav />

        <div
          className="dashboardMainPanel"
          id="adminPanel"
          style={{ overflowY: "scroll" }}
        >
          <div
            style={{
              backgroundColor: "white",
              margin: "0px",
              background: "white",
              borderBottom: "1px solid #a3c3c7",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                margin: "0px",
                padding: "10px",
              }}
            >
              /Dashboard
            </p>
          </div>

          <div className="StatsMainContainer">
            <div
              className="dashboardSection statsContainerMobile"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                backgroundColor: "#f0f2f7",
              }}
            >
              <div className="statsContainer">
                <div
                  className="piechartContainer"
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: "1 100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <InfoSquare
                      int={data.nonShippedOrders}
                      edgeColor={"#4B95F2"}
                      text={"Non Shipped Order(s)"}
                    />

                    <InfoSquare
                      int={intToDollars(data.avgSpend)}
                      edgeColor={"#1ab965"}
                      text={"Average Order Total"}
                    />

                    <InfoSquare
                      int={data.numberOfUsers}
                      edgeColor={"#c64bf2"}
                      text={"Live User(s)"}
                    />

                    <InfoSquare
                      int={companies.length}
                      edgeColor={"red"}
                      text={"Total Accounts"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="dashboardSection"
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <div
                className="announcementsContainer"
                style={{
                  background: "#fff",
                }}
              >
                <div style={{ borderBottom: "1px solid #a3c3c7" }}>
                  <p
                    style={{
                      fontSize: "22px",
                      padding: " 0px 10px",
                      fontWeight: "800",
                    }}
                  >
                    Notifications
                  </p>
                </div>
                {data.notifications.length !== 0 ? (
                  notificationsArea
                ) : (
                  <div
                    style={{
                      background: "#fff",
                      border: "none",
                      margin: "auto",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        fontSize: "3vw",
                        color: "lightgrey",
                      }}
                    >
                      No new notifications
                    </h1>
                  </div>
                )}
              </div>

              <div className="accountsContainer">
                <div
                  style={{
                    borderBottom: "1px solid #a3c3c7",
                    backgroundColor: "white",
                  }}
                >
                  <p
                    style={{
                      fontSize: "22px",
                      padding: " 0px 10px",
                      fontWeight: "800",
                    }}
                  >
                    Accounts
                  </p>
                </div>
                {companies}
              </div>
            </div>
            <div
              className="dashboardSection "
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-around",
                minHeight: "50vh",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <iframe
                  title="Companies Framework"
                  className="chart"
                  style={{ width: "50%" }}
                  src="https://charts.mongodb.com/charts-msn-ivknb/embed/charts?id=625979ee-706a-4e9c-802d-caa8474c8035&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
                <iframe
                  title="Orders By State"
                  className="chart"
                  style={{ width: "50%" }}
                  src="https://charts.mongodb.com/charts-msn-ivknb/embed/charts?id=6259804f-0b8a-4607-820b-336b6de8ca5d&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
            </div>
            <div
              className="dashboardSection "
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <iframe
                  title="Order totals by Rep - Past 3 months"
                  className="chart"
                  width="50%"
                  height="900"
                  src="https://charts.mongodb.com/charts-msn-ivknb/embed/charts?id=62598a41-ecf4-4102-885c-9a3fdd035362&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
                <iframe
                  title="Order totals / Month - Past 3 months"
                  className="chart"
                  style={{ width: "50%" }}
                  src="https://charts.mongodb.com/charts-msn-ivknb/embed/charts?id=6261c6ee-5cc4-405c-8650-6bca3ecb335a&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
            </div>
            <div
              className="dashboardSection "
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <iframe
                  title="Grand Totals"
                  className="chart"
                  style={{ width: "50%" }}
                  src="https://charts.mongodb.com/charts-msn-ivknb/embed/charts?id=631e4398-7cad-48be-8a07-1300872d691c&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
