import NavBar from "../components/navbar";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/userContext";

export default function CreateNewAccount() {
  const navigate = useNavigate();
  const [warning, setWarning] = useState("");
  const [details, setDetails] = useState({});
  const { userInformation, setNewUserInformation } = useUser();
  console.log(userInformation);

  const enterNewCompany = (e) => {
    e.preventDefault();
    axios
      .post("/api/v2/Merz/accounts/create-merz-account", {
        company: { ...details },
        merzRep: userInformation,
      })
      .then((res) => {
        console.log(res.data);
        setNewUserInformation(res.data);
        navigate("/userDashboard");
      })
      .catch((err) => {
        console.log(err);
        setWarning(String(err));
      });
  };

  return (
    <>
      <NavBar />
      <section className="section-5">
        <div className="w-layout-blockcontainer container w-container">
          <div className="form-block-6 w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form-3"
              data-wf-page-id="65834c30b06ffac0835f197b"
              data-wf-element-id="c9d39ad1-1689-1e6e-8e8d-78c0708dba36"
              onSubmit={enterNewCompany}
            >
              <div className="section-heading">
                <h1 className="view-company-large-heading">
                  Purchasing Supervisor
                </h1>
                <div className="div-block-19"></div>
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name" className="field-label">
                  First Name
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name"
                  data-name="Name"
                  placeholder=""
                  id="name"
                  onChange={(e) => {
                    setDetails({ ...details, firstName: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-4" className="field-label">
                  Last Name
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-4"
                  data-name="Name 4"
                  placeholder=""
                  id="name-4"
                  onChange={(e) => {
                    setDetails({ ...details, lastName: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-3" className="field-label">
                  Email
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-3"
                  data-name="Name 3"
                  placeholder=""
                  id="name-3"
                  onChange={(e) => {
                    setDetails({ ...details, email: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-2" className="field-label">
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, phoneNumber: e.target.value });
                  }}
                />
              </div>
              <div className="section-heading">
                <h1 className="view-company-large-heading">
                  Company Information
                </h1>
                <div className="div-block-19"></div>
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Account Number
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, accountNumber: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Company Name
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, company: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Company Email
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, companyEmail: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping Attn. Name
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      attn: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Company Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      companyPhoneNumber: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping Address
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, shippingAddress: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping P/O Box
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, shippingPOBox: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping City
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, shippingCity: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping State
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, shippingState: e.target.value });
                  }}
                />
              </div>
              <div className="form-input-field-container">
                <label htmlFor="name-5" className="field-label">
                  Shipping Zip Code
                </label>
                <input
                  required
                  type="text"
                  className="text-field-2 w-input"
                  maxLength="256"
                  name="name-2"
                  data-name="Name 2"
                  placeholder=""
                  id="name-2"
                  onChange={(e) => {
                    setDetails({ ...details, shippingZipCode: e.target.value });
                  }}
                />
              </div>
              <p style={{ color: "red" }}>{warning}</p>
              <input
                type="submit"
                value="Submit"
                data-wait="Please wait..."
                className="submit-button-2 w-button"
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
