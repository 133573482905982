import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LOGO from "../../Images/TRANSPARENT2.png";
import UseAuth from "./auth";
// import { SocketContext } from "../../sockets/websocket";

export default function SaltLogin() {
  const [username, setUserName] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState("");
  const [buttonAvail, setButtonAvail] = useState(true);
  const [warning, setWarning] = useState("");
  let navigate = useNavigate();
  const auth = UseAuth();
  // const socket = useContext(SocketContext);

  async function loginUser(e) {
    e.preventDefault();
    setButtonAvail(false);
    //axios.post("/api/login/createSalts", { userName: username, password: password })

    await axios
      .post("/api/login/login", { userName: username, password: password })
      .then((res) => {
        if (res.data.status === "ok") {
          if (res.data.return.active !== false) {
            localStorage.setItem(
              "userType",
              JSON.stringify(res.data.return.type)
            );
            localStorage.setItem("userId", JSON.stringify(res.data.return._id));
            localStorage.setItem("authToken", res.data.token);
            localStorage.setItem("username", username);

            auth.login();

            axios
              .post("/api/announcements/newAnnouncement", {
                message: "User Login: " + username,
                importance: "Low",
              })
              .then((x) => {
                // socket.send(JSON.stringify(username));
                if (res.data.return.type === true) {
                  navigate("/adminDashboard");
                } else {
                  navigate("/userDashboard");
                }
              });
          } else {
            setButtonAvail(false);
            setWarning(
              "Your account is locked, please contact your Regional Director."
            );
          }
        } else {
          setButtonAvail(true);
          setWarning(res.data.return);
        }
      });
  }

  return (
    <div
      className=""
      style={{
        padding: "10px",
        maxWidth: "100vw",
        width: "100vw",
        background: "radial-gradient(white, rgba(150,150,150,1)",
        height: "100vh",
      }}
    >
      <div style={{ margin: "auto", textAlign: "center" }}>
        <img
          alt="Medspa Network Logo"
          src={LOGO}
          style={{
            maxWidth: "500px",
            width: "100%",
            margin: "auto",
            textAlign: "center",
          }}
        />
      </div>

      <form
        onSubmit={(e) => loginUser(e)}
        style={{ width: "100%", maxWidth: "900px", margin: "auto" }}
      >
        {warning !== "" ? (
          <>
            <div style={{ color: "red" }}>{warning}</div>
            <div>
              <a href="tel:7728280628">Or Call Tech Support</a>
            </div>
          </>
        ) : null}
        <label>
          <input
            className="loginOrderInputFields"
            type="text"
            value={username}
            placeholder="Email"
            onChange={(e) => setUserName(e.target.value.toLowerCase())}
          />
        </label>
        <label>
          <input
            style={{ marginTop: "45px" }}
            className="loginOrderInputFields"
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value.replace(/-/g, ""));
            }}
          />
        </label>
        <div style={{ marginTop: "30px" }}>
          {buttonAvail ? (
            <button className="loginButton" type="submit">
              Login
            </button>
          ) : (
            <button
              className="loginButton"
              style={{ backgroundColor: "grey" }}
              type="submit"
            >
              Processing
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
